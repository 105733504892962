import { connect } from 'react-redux';
import BottomSEO from '../../../components/channel/BottomSEO';

const mapStateToProps = (state) => {
	return {
		allLatestOrders: state.getIn(['channel', 'latestOrders', 'allLatestOrders']),
		visibleLatestOrders: state.getIn(['channel', 'latestOrders', 'visibleLatestOrders']),
	};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BottomSEO);
