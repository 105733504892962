/* eslint-disable react/no-unsafe */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-deprecated */
import React from 'react';
import Immutable from 'immutable';

class BottomSEO extends React.Component {
	constructor(props) {
		super(props);

		this.startIndex = 0;
		this.fetchCount = 4;
		this.state = {
			currentOrders: [],
		};
	}

	render() {
		let param = `?dateOffset=7_14`;
		return (
			<div className="seo_footer">
				<p className="row">
					<strong>热门航线</strong>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight1"
						href={'//flights.ctrip.com/international/round-shanghai-hongkong-sha-hkg' + param}
						title="上海到香港往返机票"
					>
						上海到香港机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight2"
						href={'//flights.ctrip.com/international/round-beijing-hongkong-bjs-hkg' + param}
						title="北京到香港往返机票"
					>
						北京到香港机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight3"
						href={'//flights.ctrip.com/international/round-shanghai-seoul-sha-sel' + param}
						title="上海到首尔往返机票"
					>
						上海到首尔机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight4"
						href={'//flights.ctrip.com/international/round-shanghai-taipei-sha-tpe' + param}
						title="上海到台北往返机票"
					>
						上海到台北机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight5"
						href={'//flights.ctrip.com/international/round-shanghai-macau-sha-mfm' + param}
						title="上海到澳门往返机票"
					>
						上海到澳门机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight6"
						href={'//flights.ctrip.com/international/round-shanghai-tokyo-sha-tyo' + param}
						title="上海到东京往返机票"
					>
						上海到东京机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight7"
						href={'//flights.ctrip.com/international/round-shanghai-singapore-sha-sin' + param}
						title="上海到新加坡往返机票"
					>
						上海到新加坡机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight8"
						href={'//flights.ctrip.com/international/round-beijing-seoul-bjs-sel' + param}
						title="北京到首尔往返机票"
					>
						北京到首尔机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight9"
						href={'//flights.ctrip.com/international/round-shanghai-osaka-sha-osa' + param}
						title="上海到大阪往返机票"
					>
						上海到大阪机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight10"
						href={'//flights.ctrip.com/international/round-shanghai-phuket-sha-hkt' + param}
						title="上海到普吉岛往返机票"
					>
						上海到普吉岛机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight11"
						href={'//flights.ctrip.com/international/round-hangzhou-hongkong-hgh-hkg' + param}
						title="杭州到香港往返机票"
					>
						杭州到香港机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight12"
						href={'//flights.ctrip.com/international/round-shanghai-bangkok-sha-bkk' + param}
						title="上海到曼谷往返机票"
					>
						上海到曼谷机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight13"
						href={'//flights.ctrip.com/international/round-beijing-tokyo-bjs-tyo' + param}
						title="北京到东京往返机票"
					>
						北京到东京机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight14"
						href={'//flights.ctrip.com/international/round-beijing-singapore-bjs-sin' + param}
						title="北京到新加坡往返机票"
					>
						北京到新加坡机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight15"
						href={'//flights.ctrip.com/international/round-beijing-taipei-bjs-tpe' + param}
						title="北京到台北往返机票"
					>
						北京到台北机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight16"
						href={'//flights.ctrip.com/international/round-hongkong-shanghai-hkg-sha' + param}
						title="香港到上海往返机票"
					>
						香港到上海机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight17"
						href={'//flights.ctrip.com/international/round-beijing-bangkok-bjs-bkk' + param}
						title="北京到曼谷往返机票"
					>
						北京到曼谷机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight18"
						href={'//flights.ctrip.com/international/round-nanjing-hongkong-nkg-hkg' + param}
						title="南京到香港往返机票"
					>
						南京到香港机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight19"
						href={'//flights.ctrip.com/international/round-shanghai-kualaLumpur-sha-kul' + param}
						title="上海到吉隆坡往返机票"
					>
						上海到吉隆坡机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight20"
						href={'//flights.ctrip.com/international/round-hongkong-taipei-hkg-tpe' + param}
						title="香港到台北往返机票"
					>
						香港到台北机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight21"
						href={'//flights.ctrip.com/international/round-guangzhou-bangkok-can-bkk' + param}
						title="广州到曼谷往返机票"
					>
						广州到曼谷机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight22"
						href={'//flights.ctrip.com/international/round-xiamen-hongkong-xmn-hkg' + param}
						title="厦门到香港往返机票"
					>
						厦门到香港机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight23"
						href={'//flights.ctrip.com/international/round-shanghai-nagoya-sha-ngo' + param}
						title="上海到名古屋往返机票"
					>
						上海到名古屋机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight24"
						href={'//flights.ctrip.com/international/round-beijing-macau-bjs-mfm' + param}
						title="北京到澳门往返机票"
					>
						北京到澳门机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight25"
						href={'//flights.ctrip.com/international/round-shanghai-sydney-sha-syd' + param}
						title="上海到悉尼往返机票"
					>
						上海到悉尼机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight26"
						href={'//flights.ctrip.com/international/round-sanya-hongkong-syx-hkg' + param}
						title="三亚到香港往返机票"
					>
						三亚到香港机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight27"
						href={'//flights.ctrip.com/international/round-shanghai-hochiminhcity-sha-sgn' + param}
						title="上海到胡志明市往返机票"
					>
						上海到胡志明市机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight28"
						href={'//flights.ctrip.com/international/round-hongkong-beijing-hkg-bjs' + param}
						title="香港到北京往返机票"
					>
						香港到北京机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight29"
						href={'//flights.ctrip.com/international/round-shanghai-london-sha-lon' + param}
						title="上海到伦敦往返机票"
					>
						上海到伦敦机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight30"
						href="//flights.ctrip.com/international/search/hkg-58-incity.html"
						title="香港机票"
					>
						香港机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight31"
						href="//flights.ctrip.com/international/search/bkk-359-incity.html"
						title="曼谷机票"
					>
						曼谷机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight32"
						href="//flights.ctrip.com/international/search/sin-73-incity.html"
						title="新加坡机票"
					>
						新加坡机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight33"
						href="//flights.ctrip.com/international/search/dps-723-incity.html"
						title="巴厘岛机票"
					>
						巴厘岛机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight34"
						href="//flights.ctrip.com/international/search/lon-338-incity.html"
						title="伦敦机票"
					>
						伦敦机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight35"
						href="//flights.ctrip.com/international/search/hkt-725-incity.html"
						title="普吉岛机票"
					>
						普吉岛机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight36"
						href="//flights.ctrip.com/international/search/yvr-476-outcity.html"
						title="温哥华机票"
					>
						温哥华机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight37"
						href=" //flights.ctrip.com/international/search/dxb-220-outcity.html"
						title="迪拜机票"
					>
						迪拜机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight38"
						href="//flights.ctrip.com/international/search/mfm-59-outcity.html"
						title="澳门机票"
					>
						澳门机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight39"
						href="//flights.ctrip.com/international/search/yto-461-outcity.html"
						title="多伦多机票"
					>
						多伦多机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight40"
						href="//flights.ctrip.com/international/search/mel-358-outcity.html"
						title="墨尔本机票"
					>
						墨尔本机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight41"
						href="//flights.ctrip.com/international/search/tyo-228-outcity.html"
						title="东京机票"
					>
						东京机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight42"
						href="//flights.ctrip.com/international/search/syd-501-incity.html"
						title="悉尼机票"
					>
						悉尼机票
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight43"
						href="//flights.ctrip.com/international/search/airline-ak"
						title="亚洲航空"
					>
						亚洲航空
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight44"
						href="//flights.ctrip.com/international/search/airline-cx"
						title="迪拜机票"
					>
						国泰航空
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight45"
						href="//flights.ctrip.com/international/search/airline-ek"
						title="阿联酋航空"
					>
						阿联酋航空
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight46"
						href="//flights.ctrip.com/international/search/airline-ka"
						title="港龙航空"
					>
						港龙航空
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight47"
						href="//flights.ctrip.com/international/search/airline-sq"
						title="新加坡航空"
					>
						新加坡航空
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight48"
						href="//flights.ctrip.com/international/search/airline-ke"
						title="大韩航空"
					>
						大韩航空
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight49"
						href="//flights.ctrip.com/international/search/airline-ua"
						title="美联航"
					>
						美联航
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight50"
						href="//flights.ctrip.com/international/search/airline-qr"
						title="卡塔尔航空"
					>
						卡塔尔航空
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight51"
						href="//flights.ctrip.com/international/search/airline-hx"
						title="香港航空"
					>
						香港航空
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight52"
						href="//flights.ctrip.com/international/search/airline-dl"
						title="达美航空"
					>
						达美航空
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight53"
						href="//flights.ctrip.com/international/search/airline-oz"
						title="香港航空"
					>
						韩亚航空
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight54"
						href="//flights.ctrip.com/international/search/airline-bk"
						title="奥凯航空"
					>
						奥凯航空
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight55"
						href="//flights.ctrip.com/international/search/airline-lh"
						title="汉莎航空"
					>
						汉莎航空
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						data-ubt-key="hotFlight56"
						href="//flights.ctrip.com/international/search/airline-mh"
						title="马来西亚航空 "
					>
						马来西亚航空{' '}
					</a>
				</p>

				{this.state.currentOrders.size ? (
					<p className="row" id="pDynamicShowList">
						<strong>最新预订</strong> &nbsp;
						{this.genOrders()}
					</p>
				) : (
					''
				)}

				<p className="row">
					<strong>机票工具箱</strong>
					<a
						rel="noopener noreferrer"
						target="_blank"
						href="//m.ctrip.com/html5/flight/"
						data-ubt-key="FlightTools1"
					>
						机票手机版
					</a>
					{/* <a rel="noopener noreferrer" target="_blank" href="//www.skysea.com/" data-ubt-key="FlightTools2">
						天海
					</a> */}
					<a
						rel="noopener noreferrer"
						target="_blank"
						href="//flights.ctrip.com/domestic/checkinseat/index"
						data-ubt-key="FlightTools3"
					>
						值机
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						href="//flights.ctrip.com/international/search/schedule/"
						data-ubt-key="FlightTools4"
					>
						国际/中国港澳台航班时刻表
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						href="//flights.ctrip.com/international/search/hot-airports.html"
						data-ubt-key="FlightTools5"
					>
						国际/中国港澳台机场大全
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						href="//flights.ctrip.com/international/search/hot-cities.html"
						data-ubt-key="FlightTools6"
					>
						国际/中国港澳台城市大全
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						href="//flights.ctrip.com/international/search/hot-airlines.html"
						data-ubt-key="FlightTools7"
					>
						航空公司大全
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						href="//flights.ctrip.com/international/search/hot-flights.html"
						data-ubt-key="FlightTools8"
					>
						国际/中国港澳台航线大全
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						href="//flights.ctrip.com/international/search/hot-keywords.html"
						data-ubt-key="FlightTools9"
					>
						热门搜索词
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						href="//flights.ctrip.com/international/search/hot-countries.html"
						data-ubt-key="FlightTools0"
					>
						网站地图
					</a>
				</p>
			</div>
		);
	}

	genOrders() {
		let { currentOrders } = this.state;
		return currentOrders.map((order, index) => {
			return (
				<span key={index}>
					{order.get('timeString')}
					<a
						rel="noopener noreferrer"
						target="_blank"
						href={'//flights.ctrip.com/international/' + order.get('searchURL')}
						data-ubt-key="newRebook1"
					>
						{order.get('title')}
					</a>
				</span>
			);
		});
	}

	extract() {
		if (this.unmount) return;

		let { allLatestOrders } = this.props;
		this.startIndex += this.fetchCount;
		this.startIndex = this.startIndex % (allLatestOrders.size || 100);
		let extractCount2 = this.startIndex + this.fetchCount - allLatestOrders.size;
		extractCount2 = Math.max(0, extractCount2);
		let orders = Immutable.fromJS(
			allLatestOrders
				.slice(this.startIndex, this.fetchCount + this.startIndex)
				.concat(allLatestOrders.slice(0, extractCount2))
		);
		this.setState({
			currentOrders: orders,
		});
	}

	componentWillUnmount() {
		clearInterval(this.extractInterval);
		this.unmount = true;
	}

	componentWillMount() {
		this.props.actions.fetchLatestOrders(this.startIndex, this.fetchCount);
		this.extractInterval = setInterval(() => {
			this.extract();
		}, 5000);
	}
}

export default BottomSEO;
